import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '@app/store/app.state';
import { selectBiometricFlow } from '@app/store/selectors/biometric/biometric.selector';
import { IWizardinterface } from '@interfaces/wizard/wizard-interface';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ROUTES_VALUES } from 'src/app/Utils/constants/constants';

@Component({
  selector: 'app-steps-header',
  templateUrl: './steps-header.component.html',
  styleUrls: ['./steps-header.component.scss'],
})
export class StepsHeaderComponent  implements OnInit {
  @Input() selectActiveWizard: IWizardinterface = <IWizardinterface>{};
  @Input() isBiometricFlow = false;
  @Input() isInfant = false;
  public setComponentRoute: Array<IWizardinterface> = [];
 operationId = 0;
  hasBiometric: boolean = false;
  constructor(private store:Store<AppState>) { 
    this.store.select(selectBiometricFlow).pipe(
      take(1),
    ).subscribe((res) => {
      this.operationId = res.operationId!;
      this.hasBiometric = res.hasBiometric;
     
    })
  }

  getStyles(){
    if(this.operationId === 1){
      return{
        'background-color': '#003473',
        'border': '2px solid #003473'
      }
    }
    if(this.operationId === 2){
      return{
        'background-color': '#1b7881',
        'border': '2px solid #1b7881'
      }
    }
    return{
      'background-color': '#016ab6',
      'border': '2px solid #016ab6'
    }
  }

  getIconStyles(){
    if(this.operationId === 1){
      return{
        'color': '#003473'
      }
    }
    if(this.operationId === 2){
      return{
        'color': '#1b7881'
      }
    }
    return{
      'color': '#016ab6'
    }
  }

  ngOnInit() {
    this.getStyles()
    this.getIconStyles()
    if(this.isBiometricFlow){
      this.setComponentRoute = [
        {
          title: '',
          pageNumber: 1,
          active: true,
          route: ROUTES_VALUES.BIOMETRIC_REGISTER.SELPHI
        },
        {
          title: '',
          pageNumber: 2,
          active: false,
          route: ROUTES_VALUES.BIOMETRIC_REGISTER.DOCUMENT_FRONT
        }
      ];
      if(this.hasBiometric){
        this.setComponentRoute = [
          {
            title: '',
            pageNumber: 1,
            active: true,
            route: ROUTES_VALUES.BIOMETRIC_REGISTER.SELPHI
          },
        ]
      }
            }
   else{ 
    if(this.isInfant) {
      this.setComponentRoute = [
        {
          title: 'Personal-Info',
          pageNumber: 1,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.PERSONAL_INFO
        },
        {
          title: 'Home',
          pageNumber: 2,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.HOME
        },
        {
          title: 'MockStep1',
          pageNumber: 3,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        },
        {
          title: 'MockStep2',
          pageNumber: 4,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        },
        {
          title: 'MockStep3',
          pageNumber: 5,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        },
        {
          title: 'MockStep3',
          pageNumber: 6,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        },
        // {
        //   title: 'MockStep3',
        //   pageNumber: 7,
        //   active: false,
        //   route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        // },
        // {
        //   title: 'MockStep3',
        //   pageNumber: 8,
        //   active: false,
        //   route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        // }
      ];
    } else {
      this.setComponentRoute = [
        {
          title: 'Personal-Info',
          pageNumber: 1,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.PERSONAL_INFO
        },
        {
          title: 'Home',
          pageNumber: 2,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.HOME
        },
        {
          title: 'MockStep1',
          pageNumber: 3,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        },
        {
          title: 'MockStep2',
          pageNumber: 4,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        },
        {
          title: 'MockStep3',
          pageNumber: 5,
          active: false,
          route: ROUTES_VALUES.CUENTA_ADULTO.SELPHI
        }
      ];
    }
    
  }
  }
}
