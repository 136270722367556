<div class="out-of-service__main-container">

  <ca-header title="MAIN_HEADER.TITLE" [hasReturn]="false" />

  <ca-responsive-manager
    [isCustomImageEnabled]="true"
    [isImageFarLeft]="true"
    setBackgroundImage="../../../../assets/Images/generic/ZAMBO_TIME_OUT.svg"
    [setTemplateInfo]="templateInfo"
    class="out-of-service__container"
  />
</div>
<ng-template #templateInfo>
  <ng-container>
    <div class="out-of-service__container-content">
      <div class="out-of-service__container-title" data-test="account-requirement-title">
        {{ 'FORBIDDEN_HOUR.TITLE' | translate }}
      </div>
      <div class="out-of-service__container-content-image">
        <img
          class="out-of-service__container-content-image-svg"
          src="../../../../assets/Images/generic/ZAMBO_TIME_OUT.svg"
        />
      </div>
      <div class="out-of-service__container-info">
        <div class="out-of-service__container-description" data-test="btn-next" data-test="account-requirement-description">
          <div class="out-of-service__container-description-line">
            {{ 'FORBIDDEN_HOUR.DESCRIPTION' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
