import { createReducer, on, Action } from "@ngrx/store";
import { BiometricsActions } from "@app/store/actions/biometric/biometric.actions";
import { BiometricFlow, SelphIdData, SelphIdImages, SelphiResult, teResultData } from "@interfaces/index";
import { UserCheckBlockResponse } from "@interfaces/biometric-auth/user-check-block-response.interface";
import { ICapturedInfo } from "@interfaces/identity-document-cda/identity-document-cda.interface";

export interface BiometricDataState {
    biometricFlow: BiometricFlow;
    documentData: SelphIdImages & SelphIdData;
    selphiData: SelphiResult;
    teInfoData: teResultData
    userCheckBlockResponse: UserCheckBlockResponse,
    numero_plastico?: string;
}

export const initialState: BiometricDataState = {
    biometricFlow: {
        clientId: '',
        documentId: '',
        documentType: 0,
        channelId: 0,
        operationId: null,
        hasBiometric: false,
        statusId: 0,
        token: '',
        biometricTemplate: '',
        isTest: false,
        consentIsChecked: false,
        userBlockToken: '',
        ip: '',
        os: '',
        model: ''
    },
    documentData: {
        frontDocument: '',
        faceImage: '',
        backDocument: '',
        documentNumber: '',
        dateOfBirth: '',
        dateOfExpiry: '',
        firstName: '',
        lastName: '',
    },
    selphiData: {
        bestImage: '',
        bestImageCropped: '',
        bestImageTokenized: '',
        template: '',
        templateRaw: ''
    }, teInfoData: {
        teData: ''
    },
    userCheckBlockResponse: {
    id: 0,
    proccessId: '',
    clientIdentification: '',
    makerName: '',
    makerDescription: '',
    checkerName: '',
    checkerDescription: '',
    lastStatusAttention: '',
    statusAttention: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: ''
},
    numero_plastico: ''
};

export const biometricsReducer = createReducer(
    initialState,
    on(BiometricsActions.set_biometrics, (state, { biometrics }) => {
        return { ...state, biometricFlow: biometrics };
    }),
    on(BiometricsActions.set_info_from_id_document, (state, { idDocumentData }) => {
        return { ...state, documentData: idDocumentData };
    }),
    on(BiometricsActions.set_info_selphi, (state, { selphiInfo }) => {
        return { ...state, selphiData: selphiInfo };
    }),
    on(BiometricsActions.set_te_info, (state, { teInfo }) => {
        return { ...state, teInfoData: teInfo };
    }),
    on(BiometricsActions.set_user_check_block_response, (state, { userCheckBlockResponse }) => {
        return { ...state, userCheckBlockResponse };
    }),
    on(BiometricsActions.set_captured_document_info, (state, documentInfo) => {
        return { ...state, numero_plastico: documentInfo.documentNumber };
    }),
);
