<div class="steps-header__container-col">
  <ng-container *ngFor="let pageItem of setComponentRoute; let i = index">
    <div
      *ngIf="
        selectActiveWizard.pageNumber == pageItem.pageNumber;
        else showPassed
      "
      class="steps-header__row-check-style">
      <div class="steps-header__number-circle-activated"
      [ngStyle]="getStyles()"
      >
        <span class="steps-header__number-label-activated">
          {{ pageItem.pageNumber }}
        </span>
      </div>
    </div>

    <ng-template #showPassed>
      <div
        *ngIf="
          selectActiveWizard.pageNumber > pageItem.pageNumber;
          else showInactive
        "
        class="steps-header__row-check-style">
        <div class="steps-header__number-circle-check-activated" [ngStyle]="getIconStyles()" >
          <mat-icon
            aria-hidden="false"
            aria-label="Example home icon"
            fontIcon="check"
            class="steps-header__check-label-activated" 
            [ngStyle]="getIconStyles()"
            >
          </mat-icon>
        </div>
      </div>
    </ng-template>
    <ng-template #showInactive>
      <div class="steps-header__row-inactive-container">
        <div class="steps-header__number-circle-desactivated" 
        >
          <span class="steps-header__number-label-desactivated">
            {{ pageItem.pageNumber }}
          </span>
        </div>
      </div>
    </ng-template>
    <div
      *ngIf="i < setComponentRoute.length - 1"
      [ngClass]="
          selectActiveWizard.pageNumber < pageItem.pageNumber
          ? 'steps-header__row-dash-symbol-style'
          : 'steps-header__row-dash-symbol-style-active'
      ">
      -
    </div>
  </ng-container>
</div>
