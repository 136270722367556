<ng-container>
  <ng-template [ngIf]="scVar.xs">
    <ng-template [ngIf]="isStepperActive">
      <div class="responsive-manager__header-container">
        <app-steps-header
          class="responsive-manager__steps-main"
          [selectActiveWizard]="setStepperInfo"
          [isInfant]="isInfant"
        />
      </div>
    </ng-template>
    <div class="responsive-manager__container-sm">
      <ng-container [ngTemplateOutlet]="setTemplateInfo"></ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template [ngIf]="scVar.s">
    <img *ngIf="enablePortraitImage" class="responsive-manager__header-image-s" src="{{tabletBackgroundHeaderImage}}"/>
    <ng-template [ngIf]="isStepperActive">
      <div class="responsive-manager__header-container">
        <app-steps-header
          class="responsive-manager__steps-main"
          [selectActiveWizard]="setStepperInfo"
          [isInfant]="isInfant"
        />
      </div>
    </ng-template>
    <div class="responsive-manager__container-s">
      <ng-container [ngTemplateOutlet]="setTemplateInfo"></ng-container>
    </div>
    <img *ngIf="enablePortraitImage" class="responsive-manager__footer-image-s" src="{{tabletBackgroundFooterImage}}"/>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template [ngIf]="scVar.md">
    <div class="responsive-manager__outer-container-md">
      <ng-template [ngIf]="isBackgroundImageEnabled">
        <ng-container *ngIf="isImageFarLeft">
          <div
            [ngClass]="
              isCustomImageEnabled
                ? 'responsive-manager__container-image-md-background-custom'
                : 'responsive-manager__container-image-md-background'
            "
          >
            <img
              [ngClass]="
                isCustomImageEnabled
                  ? 'responsive-manager__image-styles-md-custom'
                  : 'responsive-manager__image-styles-md'
              "
              src="{{ setBackgroundImage }}"
            />
          </div>

          <div class="responsive-manager__container-md-background">
            <ng-template [ngIf]="isStepperActive">
              <div class="responsive-manager__header-container">
                <app-steps-header
                  class="responsive-manager__steps-main"
                  [selectActiveWizard]="setStepperInfo"
                  [isInfant]="isInfant"
                />
              </div>
            </ng-template>
            <ng-container [ngTemplateOutlet]="setTemplateInfo"></ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="!isImageFarLeft">
          <div class="responsive-manager__container-md-background">
            <ng-template [ngIf]="isStepperActive">
              <div class="responsive-manager__header-container">
                <app-steps-header
                  class="responsive-manager__steps-main"
                  [selectActiveWizard]="setStepperInfo"
                  [isInfant]="isInfant"
                />
              </div>
            </ng-template>
            <ng-container [ngTemplateOutlet]="setTemplateInfo"></ng-container>
          </div>
          <div
            [ngClass]="
              isCustomImageEnabled
                ? 'responsive-manager__container-image-md-background-custom'
                : 'responsive-manager__container-image-md-background'
            "
          >
            <img
              [ngClass]="
                isCustomImageEnabled
                  ? 'responsive-manager__image-styles-md-custom'
                  : 'responsive-manager__image-styles-md'
              "
              src="{{ setBackgroundImage }}"
            />
          </div>
        </ng-container>
      </ng-template>
      <ng-template [ngIf]="!isBackgroundImageEnabled">
        <div class="responsive-manager__container-md">
          <ng-template [ngIf]="isStepperActive">
            <div class="responsive-manager__header-container">
              <app-steps-header
                class="responsive-manager__steps-main"
                [selectActiveWizard]="setStepperInfo"
                [isInfant]="isInfant"
              />
            </div>
          </ng-template>
          <ng-container [ngTemplateOutlet]="setTemplateInfo"></ng-container>
        </div>
      </ng-template>
    </div>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template [ngIf]="scVar.l">
    <div class="responsive-manager__background">
      <div class="responsive-manager__outer-container">
        <ng-template [ngIf]="isStepperActive">
          <div class="responsive-manager__header-container">
            <app-steps-header
              class="responsive-manager__steps-main"
              [selectActiveWizard]="setStepperInfo"
              [isInfant]="isInfant"
            />
          </div>
        </ng-template>
        <div class="responsive-manager__container-l">
          <ng-container [ngTemplateOutlet]="setTemplateInfo"></ng-container>
        </div>
      </div>
      <div class="behavior">
        <div class="sub-behavior1"></div>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-container>
  <ng-template [ngIf]="scVar.xl">
    <div class="responsive-manager__background">
      <div class="responsive-manager__outer-container">
        <ng-template [ngIf]="isStepperActive">
          <div class="responsive-manager__header-container">
            <app-steps-header
              class="responsive-manager__steps-main"
              [selectActiveWizard]="setStepperInfo"
              [isInfant]="isInfant"
            />
          </div>
        </ng-template>
        <div class="responsive-manager__container-xl">
          <ng-container [ngTemplateOutlet]="setTemplateInfo"></ng-container>
        </div>
      </div>
      <div class="behavior">
        <div class="sub-behavior1"></div>
      </div>
    </div>
  </ng-template>
</ng-container>
<ca-loading></ca-loading>
