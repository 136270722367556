import { ActionReducerMap } from "@ngrx/store";
import { AppState } from "./app.state";
import { wizardReducer } from "./reducers/cuenta-adulto/wizard/wizard.reducer";
import { listAccountReducer } from "./reducers/cuenta-adulto/list-account/list-account.reducer";
import { adultAccountOrchestationReducer } from "./reducers/cuenta-adulto/cuenta-adulto.reducer";
import { biometricsReducer } from "./reducers/biometric/biometric.reducer";
import { infantilAccountOrchestationReducer } from "./reducers/cuenta-infantil/cuenta-infantil.reducer";
import { juvenilAccountOrchestationReducer } from './reducers/cuenta-juvenil/cuenta-juvenil.reducer';
import { binnacleReducer } from "./reducers/binnacle/binnacle.reducer";
import { christmasAccountOrchestationReducer } from "./reducers/cuenta-navidad/cuenta-navidad.reducer";

export const combinedReducers: ActionReducerMap<AppState> = {
  wizard: wizardReducer,
  list_account: listAccountReducer,
  adult_account: adultAccountOrchestationReducer,
  infantil_account: infantilAccountOrchestationReducer,
  juvenil_account: juvenilAccountOrchestationReducer,
  christmas_account: christmasAccountOrchestationReducer,
  biometrics: biometricsReducer,
  binnacle: binnacleReducer
};
