import { environment } from "@environments/environment";

export const isWithingRange = () => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();

  const isValidHourRange =
    currentHour >= environment.outOfServiceHourInit &&
    currentHour <= environment.outOfServiceHourEnd;

  const isValidMinutesInit =
    currentHour >= environment.outOfServiceHourInit &&
    currentMinutes >= environment.outOfServiceMinuteInit

  const isValidMinutesEnd =
    currentHour <= environment.outOfServiceHourEnd &&
    currentMinutes <= environment.outOfServiceMinuteEnd

  const isWithinTimeRange =
    isValidHourRange && isValidMinutesInit && isValidMinutesEnd;

  return isWithinTimeRange;
};
