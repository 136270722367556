
export class IShowModalIdentityInfo {
    initial_id?: string;
    document_id?: string;
    document_number?: string;
    isOnOnboarding?: boolean;
    constructor(
      initial_id?: string,
      cedula?: string,
      document?: string,
      isOnOnboarding?: boolean
    ) {
      this.initial_id = initial_id || '';
      this.document_id = cedula || '';
      this.document_number = document || '';
      this.isOnOnboarding = false
    }
  }
  
  export class ICapturedInfo {
    documentId?: string;
    documentNumber?: string;
    constructor(
      cedula?: string,
      document?: string
    ) {
      this.documentId = cedula || '';
      this.documentNumber = document || '';
    }
  }

  export class IManualParentCapturedInfo {
    documentBirthDate?: string;
    documentExpirationDate?: string;
    documentParentName?: string;
    documentParentId?: string;
    documentParentIdFront?: string;
    documentParentIdBack?: string;
    constructor(
      docBirthDate?: string,
      docExpirationDate?: string,
      docParentName?: string,
      docParent?: string,
      docParentFront?: string,
      docParentBack?: string
    ){
      this.documentBirthDate = docBirthDate || '';
      this.documentExpirationDate = docExpirationDate || '';
      this.documentParentName = docParentName || '';
      this.documentParentId = docParent || '';
      this.documentParentIdFront = docParentFront || '';
      this.documentParentIdBack = docParentBack || '';
    }
  }

  export class IManualInfatCapturedInfo {
    documentBirthDate?: string;
    documentExpirationDate?: string;
    documentName?: string;
    documentParentName?: string;
    documentParentId?: string;
    documentInfantIdFront?: string;
    documenInfantIdBack?: string;
    constructor(
      docBirthDate?: string,
      docExpirationDate?: string,
      docName?: string,
      docParentName?: string,
      docParent?: string,
      docInfantFront?: string,
      doInfantBack?: string
    ){
      this.documentBirthDate = docBirthDate || '';
      this.documentExpirationDate = docExpirationDate || '';
      this.documentParentName = docParentName || '';
      this.documentParentId = docParent || '';
      this.documentName = docName || '';
      this.documentInfantIdFront = docInfantFront || '';
      this.documenInfantIdBack = doInfantBack || '';
    }
  }
  