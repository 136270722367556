import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-not-found.",
  templateUrl: "not-found.page.html",
  styleUrls: ["not-found.page.scss"],
})
export class NotFoundPage {

  translate: TranslateService;


  constructor(

    private translateService: TranslateService
  ) {
    this.translate = this.translateService;
  
  }

}
