import { Component, Input, TemplateRef } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  IResponsiveManagerInterface,
  ResponseManagerInit,
} from "./responsive-manager.interface";
import { IWizardinterface } from "@interfaces/wizard/wizard-interface";
import { Globals } from '@app/globals';

@Component({
  selector: "ca-responsive-manager",
  templateUrl: "./responsive-manager.component.html",
  styleUrls: ["./responsive-manager.component.scss"],
})
export class ResponsiveManagerComponent {

  @Input() setTemplateInfo: TemplateRef<any> | null = null;
  @Input() isStepperActive: boolean = false;
  @Input() setStepperInfo: IWizardinterface = <IWizardinterface>{};
  @Input() isBackgroundImageEnabled: boolean = true;
  @Input() setBackgroundImage: string = '../../../../assets/Images/background/tablet_background.svg';
  @Input() isCustomImageEnabled: boolean = false;
  @Input() isImageFarLeft: boolean = false;
  @Input() enablePortraitImage: boolean = false;
  @Input() tabletBackgroundFooterImage: string = '../../../../assets/Images/background/tablet-portrait-footer.svg';
  @Input() tabletBackgroundHeaderImage: string = '../../../../assets/Images/background/tablet-portrait-header.svg';
  @Input() isInfant: boolean = false;
  scVar: IResponsiveManagerInterface = new ResponseManagerInit();

  constructor(private breakpointObserver: BreakpointObserver) {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ]);

    layoutChanges.subscribe((result) => {
      this.scVar = new ResponseManagerInit();
      switch (true) {
        case result.breakpoints[Breakpoints.XSmall]:
          {
            this.scVar.xs = true;
            Globals.smartphoneSize = true;
            Globals.tabletSize = false;
            Globals.desktopSize = false;
        }

          break;

        case result.breakpoints[Breakpoints.Small]:
          {
            this.scVar.s = true;
            Globals.smartphoneSize = false;
            Globals.tabletSize = true;
            Globals.desktopSize = false;
        }

          break;

        case result.breakpoints[Breakpoints.Medium]:
          {
            this.scVar.md = true;
            Globals.smartphoneSize = false;
            Globals.tabletSize = true;
            Globals.desktopSize = false;
        }

          break;
        case result.breakpoints[Breakpoints.Large]:
          {
            this.scVar.l = true;
            Globals.smartphoneSize = false;
            Globals.tabletSize = false;
            Globals.desktopSize = true;
        }

          break;

        case result.breakpoints[Breakpoints.XLarge]:
          {
            this.scVar.xl = true;
            Globals.smartphoneSize = false;
            Globals.tabletSize = false;
            Globals.desktopSize = true;
        }

          break;

        default: {
          this.scVar.s = true;
          Globals.smartphoneSize = true;
          Globals.tabletSize = false;
          Globals.desktopSize = false;
        }
      }
    });

  }
}
