<mat-toolbar title="" color="primary" *ngIf="operationId !== 1" [ngStyle]="{'background-color': bgColor}">
  <mat-toolbar-row>

    <span *ngIf="hasReturn" title=""
      (click)="backClicked()"
      class="header__toolbar-back">
      <
    </span>
    <span title="" class="header__toolbar-title">{{ title | translate }}</span>
  </mat-toolbar-row>
</mat-toolbar>


<mat-toolbar title="" *ngIf="operationId === 1" [ ngStyle]="operationId === 1 && 'background-color': #003473;">
  <mat-toolbar-row>

    <span *ngIf="hasReturn" title=""
      (click)="backClicked()"
      class="header__toolbar-back">
      <
    </span>
    <span title="" class="header__toolbar-title">{{ title | translate }}</span>
  </mat-toolbar-row>
</mat-toolbar>
