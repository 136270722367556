import { KeyPairInterface } from "@components/shared/forms/element-base";
import { IFormValidated } from "@interfaces/shared/dynamic-form.interface";

export class IFormDocumentData extends IFormValidated {
  override formValues: IFormDocumentDataValues = new IFormDocumentDataValues();
}

export interface DocumentInfoKeyPair extends KeyPairInterface {
  label?: string;
  value: any;
}

export interface IDocumentInterface {
  documentValues: DocumentInfoKeyPair | [];
}

export class IFormDocumentDataValues {
  cedula?: string;
  document?: string;
  constructor(
    cedula?: string,
    document?: string
  ) {
    this.cedula = cedula || '';
    this.document = document || '';
  }
}

