import { createReducer, on } from "@ngrx/store";

import { WizardActions } from "../../../actions/cuenta-adulto/wizard/wizard.actions";
import { IWizardinterface } from "@interfaces/wizard/wizard-interface";

export const initialState: IWizardinterface = {
  title: '',
  pageNumber: 0,
  active: true,
  route: ''
};

export const wizardReducer = createReducer(
  initialState,
  on(WizardActions.add_wizard_step, (state, payload) => {
    return {...state, ... payload};
  })
);
