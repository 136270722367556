import { BiometricFlow } from '@interfaces/index';
import { createSelector, } from "@ngrx/store";
import { AppState } from "src/app/store/app.state";



export const selectBiometricFlow = createSelector(
    (state: AppState) => state.biometrics.biometricFlow,
    (biometricsFlow: BiometricFlow) => biometricsFlow
);

export const selectDocumentData = createSelector(
    (state: AppState) => state.biometrics.documentData,
    (documentData) => documentData
);

export const selectSelphiData = createSelector(
    (state: AppState) => state.biometrics.selphiData,
    (selphiData) => selphiData
);

export const selectTeInfo = createSelector(
    (state: AppState) => state.biometrics.teInfoData,
    (teData) => teData
);