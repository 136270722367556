import { KeyPairInterface } from "@components/shared/forms/element-base";
import { IFormValidated } from "@interfaces/shared/dynamic-form.interface";
import {
  BeneficiariesFormData,
  IFormBeneficiariesInfo,
} from "./beneficiaries.interface";

export interface OcupationInfoKeyPair extends KeyPairInterface {
  label?: string;
  value: any;
  riskValue?: string;
}

export interface IInconmeInterface {
  incomeValues: OcupationInfoKeyPair | [];
}

export class IFormOcupationInfo extends IFormValidated {
  override formValues: IFormOcupationInfoDataValues =
    new IFormOcupationInfoDataValues();
}

export class IFormOcupationInfoDataValues {
  profesion?: OcupationInfoKeyPair;
  income?: OcupationInfoKeyPair;
  salary?: number;
  education?: OcupationInfoKeyPair;
  province_work?: OcupationInfoKeyPair;
  zone_work?: OcupationInfoKeyPair;
  constructor(
    profesion?: OcupationInfoKeyPair,
    income?: OcupationInfoKeyPair,
    salary?: number,
    education?: OcupationInfoKeyPair,
    province_work?: OcupationInfoKeyPair,
    zone_work?: OcupationInfoKeyPair
  ) {
    this.profesion = profesion;
    this.income = income;
    this.salary = salary || 0;
    this.education = education,
    this.province_work = province_work,
    this.zone_work = zone_work
  }
}

export class setValidConditionsOnSubmit {
  isValidOcupationForm: boolean = false;
  isBenefeciariesLenghtCorrect: boolean = false;
  isRelationPercentCorrect: boolean = false;
  isNonRepeatedIdentification: boolean = false;
  isSameIdentificationUserAsBeneficiary: boolean = false;
  isBeneficiaryInsidePermitedLenght: boolean = false;
  labelErrorArray: string[] = [];
}

export class IsetOcupationInfoToStore {
  ocupationInfo: IFormOcupationInfoDataValues =
    new IFormOcupationInfoDataValues();
  beneficiaryInfo: Array<IFormBeneficiariesInfo> =
    new Array<IFormBeneficiariesInfo>();
}
