export const ROUTES_VALUES = {
  PRODUCTS: "products",
  LIST_ACOUNTS: "list-account",
  CUENTA_ADULTO: {
    INIT_STEPPER: "Inicio",
    LIST_ACCOUNT: "list-account",
    PERSONAL_INFO: "personal-info",
    SHOW_REQUIREMENTS: "show-requirements",
    HOME: "home",
    OTP_VALIDATION: "otp",
    DOCUMENT_FRONT: "identity-document",
    SELPHI: "selphi",
    OCUPATION: "ocupation-info",
    LOCATION_INFO: "location-info",
    DOCUMENT_FAKE_FORM: "document-fake-form",
    BENEFICIARIES: "beneficiaries",
    BANK_PRODUCT: "bank-product",
    FINAL_SCREEN: "final-screen",
  },
  CUENTA_JUVENIL: {
    INIT_STEPPER: "Inicio",
    LIST_ACCOUNT: "list-account",
    PERSONAL_INFO: "personal-info",
    SHOW_REQUIREMENTS: "show-requirements",
    HOME: "home",
    OTP_VALIDATION: "otp",
    DOCUMENT_FRONT: "identity-document",
    SELPHI: "selphi",
    OCUPATION: "ocupation-info",
    LOCATION_INFO: "location-info",
    DOCUMENT_FAKE_FORM: "document-fake-form",
    BENEFICIARIES: "beneficiaries",
    BANK_PRODUCT: "bank-product",
    FINAL_SCREEN: "final-screen",
  },
  CUENTA_INFANTIL: {
    INIT_STEPPER: "Inicio",
    LIST_ACCOUNT: "list-account",
    PERSONAL_INFO: "personal-info",
    SHOW_REQUIREMENTS: "show-requirements",
    HOME: "home",
    OTP_VALIDATION: "otp",
    DOCUMENT_FRONT: "identity-document",
    DOCUMENT_CHILD_FRONT: "identity-child-document",
    SELPHI: "selphi",
    OCUPATION: "ocupation-info",
    LOCATION_INFO: "location-info",
    DOCUMENT_FAKE_FORM: "document-fake-form",
    BENEFICIARIES: "beneficiaries",
    BANK_PRODUCT: "bank-product",
    FINAL_SCREEN: "final-screen",
  },
  CUENTA_NAVIDAD: {
    INIT_STEPPER: "Inicio",
    LIST_ACCOUNT: "list-account",
    PERSONAL_INFO: "personal-info",
    SHOW_REQUIREMENTS: "show-requirements",
    HOME: "home",
    OTP_VALIDATION: "otp",
    DOCUMENT_FRONT: "identity-document",
    SELPHI: "selphi",
    OCUPATION: "ocupation-info",
    LOCATION_INFO: "location-info",
    DOCUMENT_FAKE_FORM: "document-fake-form",
    BENEFICIARIES: "beneficiaries",
    BANK_PRODUCT: "bank-product",
    SELECT_PERIODICITY: "select-periodicity",
    FINAL_SCREEN: "final-screen",
    VALIDATE_CLIENT: "validate-client",
    EXISTENT_SELPHI: "selphi-existent-client",
    EXISTENT_DOCUMENT: "identity-document-existent-client",
  },
  BIOMETRIC_REGISTER: {
    CONSENT_EXPLICIT: "consent-explicit",
    DOCUMENT_FRONT: "identity-document",
    SELPHI: "selphi",
    FINAL_SCREEN: "final-screen",
    ALTERNATE_BIOMETRIC_FLOW: "alternate-biometric-flow",
  },
  ALTERNATE_PAGES: {
    OUT_OF_BOUNDS: "out-of-bounds",
    NOT_FOUND: "not-found",
    OUT_OF_SERVICE: "out-of-service",
  }
};

export const PRODUCT_ROUTES_VALUES = {
  CUENTA_ADULTO: "cda",
  CUENTA_JUVENIL: "cujv",
  CUENTA_INFANTIL: "cuin",
  CUENTA_NAVIDAD: "cnav",
  BIOMETRIC_REGISTER: "biometric",
  PAGE_REDIRECT: "portal",
};

export const SERVICE_ROUTES_VALUES = {
  CUENTA_ADULTO: "CA01",
  CUENTA_JUVENIL: "CIPJ",
  CUENTA_INFANTIL: "CA65",
  CUENTA_NAVIDAD: "CA59",
  BIOMETRIC_REGISTER: "biometric",
};

