import { createReducer, on } from "@ngrx/store";
import { IListAccountInterface } from "@interfaces/list-account/list-account.interface";
import { ListAccountActions } from "../../../actions/cuenta-adulto/list-account/list-account.actions";

export const initialState: IListAccountInterface = {
  productInfo: undefined,
  characteristicsInfo: undefined,
  getAllCharacteristics: undefined
};

export const listAccountReducer = createReducer(
  initialState,
  on(ListAccountActions.get_characteristics, (state, payload) => {
    return {...state, ... payload};
  }),
  on(ListAccountActions.get_account_info, (state, payload) => {
    return {...state, ... payload};
  }),
  on(ListAccountActions.get_all_characteristics, (state, payload) => {
    return {...state, ... payload};
  })
);
