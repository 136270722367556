<div class="not-found__main-container">

  <ca-header title="Atención" [hasReturn]="false" />

  <ca-responsive-manager
    [isCustomImageEnabled]="true"
    [isImageFarLeft]="true"
    setBackgroundImage="../../../../assets/Images/warning_sign.svg"
    [setTemplateInfo]="templateInfo"
    class="not-found__container"
  />
</div>
<ng-template #templateInfo>
  <ng-container>
    <div class="not-found__container-content">
      <div class="not-found__container-content-image">
        <img
          class="not-found__container-content-image-svg"
          src="../../../../assets/Images/warning_sign.svg"
        />
      </div>
      <div class="not-found__container-info">
        <div class="not-found__container-title" data-test="account-requirement-title">
          {{ 'FORBIDDEN_ACCESS.NOT_FOUND_TITLE' | translate }}
        </div>
        <div class="not-found__container-description" data-test="btn-next" data-test="account-requirement-description">
          <div class="not-found__container-description-line">
            {{ 'FORBIDDEN_ACCESS.NOT_FOUND_DESCRIPTION' | translate }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
