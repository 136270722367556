import { IUtilsResponse } from "@interfaces/binnacle/binnacle.interface";
import { createActionGroup, props } from "@ngrx/store";

export const BinnacleActions = createActionGroup({
  source: "Binnacle Info",
  events: {
    SET_TRACING_DATA: props<IUtilsResponse>(),
  },
});

export const JuvenilBinnacleActions = createActionGroup({
  source: "Juvenil Binnacle Info",
  events: {
    SET_TRACING_DATA: props<IUtilsResponse>(),
  },
});
export const InfantilBinnacleActions = createActionGroup({
  source: "Infantil Binnacle Info",
  events: {
    SET_TRACING_DATA: props<IUtilsResponse>(),
  },
});

export const ChristmasBinnacleActions = createActionGroup({
  source: "Christmas Binnacle Info",
  events: {
    SET_TRACING_DATA: props<IUtilsResponse>(),
  },
});
