import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PRODUCT_ROUTES_VALUES, ROUTES_VALUES } from "./Utils/constants/constants";
import { environment } from "@environments/environment";
import { NotFoundPage } from "./container/alternate-pages/not-found/not-found.page";
import { isWithingRange } from "./Utils/operation-range";
import { OutOfServicePage } from "./container/alternate-pages/out-of-service/out-of-service.page";

let routes: Routes = [];

const pattern = new RegExp(environment.onboardingDominion);


  if (pattern.test(window.location.href)) {
    if (isWithingRange()) {
    routes = [
    {
      path: "",
      redirectTo: ROUTES_VALUES.PRODUCTS,
      pathMatch: "full",
    },
    {
      path: PRODUCT_ROUTES_VALUES.PAGE_REDIRECT,
      loadChildren: () =>
        import("./container/alternate-pages/alternate-pages.module").then(
          (m) => m.AlternatePagesModule
        ),
    },
    {
      path: "**",
      component: NotFoundPage
    },
  ];

  } else {

    routes = [
      {
        path: "",
        redirectTo: ROUTES_VALUES.ALTERNATE_PAGES.OUT_OF_SERVICE,
        pathMatch: "full",
      },
      {
        path: "**",
        redirectTo: ROUTES_VALUES.ALTERNATE_PAGES.OUT_OF_SERVICE,
      },
      {
        path: ROUTES_VALUES.ALTERNATE_PAGES.OUT_OF_SERVICE,
        component: OutOfServicePage,
      },
    ];

  }
} else {
  routes = [
    {
      path: "",
      redirectTo: ROUTES_VALUES.ALTERNATE_PAGES.NOT_FOUND,
      pathMatch: "full",
    },
    {
      path: PRODUCT_ROUTES_VALUES.BIOMETRIC_REGISTER,
      loadChildren: () =>
        import("./container/biometric/biometric.module").then(
          (m) => m.BiometricModule
        ),
    },
    {
      path: "**",
      redirectTo: ROUTES_VALUES.ALTERNATE_PAGES.NOT_FOUND,
    },
    {
      path: ROUTES_VALUES.ALTERNATE_PAGES.NOT_FOUND,
      component: NotFoundPage,
    },
  ];
  }

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
