import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-out-of-service.",
  templateUrl: "out-of-service.page.html",
  styleUrls: ["out-of-service.page.scss"],
})
export class OutOfServicePage {

  translate: TranslateService;


  constructor(

    private translateService: TranslateService
  ) {
    this.translate = this.translateService;
  
  }

}
