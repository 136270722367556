import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cda-app';
  constructor(private matIconRegistry: MatIconRegistry,     private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      `sun`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/Images/icon/sun.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `face_capture`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/Images/icon/camera_face.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `non_eyeglasses`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/Images/icon/eyeglasses.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `id_capture`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/Images/icon/id.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `person_move`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/Images/icon/person_move.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `change_camera`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/Images/icon/change_camera.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      `swap_camera`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/Images/icon/swap_camera.svg`)
    );

  }
}
