import { Component } from '@angular/core';
import { LoadingHandlerService } from '@services/loading/loading-handler.service';

@Component({
  selector: 'ca-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  
  loadingActive: boolean = true;
  isLoadingDelayed: boolean = false;

  constructor(
    public loadingHandler: LoadingHandlerService
  ) {
    this.loadingHandler.showLoading.subscribe(this.showLoading.bind(this));
  }

  showLoading = (state: boolean): void => {
    this.isLoadingDelayed = false;
    this.loadingActive = state;

    setTimeout(() => this.isLoadingDelayed = true, 5000);
  };

}
