import { Component, Input } from '@angular/core';
import {Location} from '@angular/common';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { BiometricFlow } from '@interfaces/index';
import { selectBiometricFlow } from '@app/store/selectors/biometric/biometric.selector';

@Component({
  selector: 'ca-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() title: string = "";
  @Input() hasReturn: boolean = true;
  @Input() bgColor = "";
 operationId = 0;
  constructor(private _location: Location, private store: Store<AppState>) 
  {
    this.store.select(selectBiometricFlow).subscribe((res:any) => {
      this.operationId = res.operationId!;
    });
  }

  backClicked() {
    this._location.back();
  }

}
