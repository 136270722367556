import { createReducer, on, Action } from "@ngrx/store";
import { BinnacleActions } from "@app/store/actions/binnacle/binnacle.actions";
import { BinnacleDataState, IUtilsResponse } from "@interfaces/binnacle/binnacle.interface";

export const initialState: BinnacleDataState = {
    tracingData: new IUtilsResponse(),
};

export const binnacleReducer = createReducer(
    initialState,

    on(BinnacleActions.set_tracing_data, (state, payload) => {
        return {...state, tracingData: payload};
      }),
);
