import { ICapturedInfo } from '@interfaces/identity-document-cda/identity-document-cda.interface';
import { UserCheckBlockResponse } from './../../../interfaces/biometric-auth/user-check-block-response.interface';

import { BiometricFlow, SelphIdData, SelphIdImages, SelphiResult, teResultData } from "@interfaces/index";
import { createActionGroup, props } from "@ngrx/store";

export const BiometricsActions = createActionGroup({
    source: '[Biometric] Biometric Info',
    events: {
        'SET_BIOMETRICS': props<{ biometrics: BiometricFlow }>(),
        'SET_INFO_FROM_ID_DOCUMENT': props<{ idDocumentData: SelphIdImages & SelphIdData }>(),
        'SET_INFO_SELPHI': props<{ selphiInfo: SelphiResult }>(),
        'SET_TE_INFO': props<{ teInfo: teResultData }>(),
        'SET_USER_CHECK_BLOCK_RESPONSE': props<{ userCheckBlockResponse: UserCheckBlockResponse }>(),
        'set_captured_document_info': props<ICapturedInfo>()
    }
})



