import { createActionGroup, props } from '@ngrx/store';
import { IWizardinterface } from '@interfaces/wizard/wizard-interface';
 
export const WizardActions = createActionGroup({
  source: 'WIZARD',
  events: {
    'ADD_WIZARD_STEP': props<IWizardinterface>(),
    'REMOVE_WIZARD_STEP': props<IWizardinterface>(),
  },
});
 
