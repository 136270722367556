import { KeyPairInterface } from "@components/shared/forms/element-base";
import { IFormValidated } from "@interfaces/shared/dynamic-form.interface";

export class IFormPersonalData extends IFormValidated {
  override formValues: IFormPersonalDataValues = new IFormPersonalDataValues();
}

export interface PersonalInfoKeyPair extends KeyPairInterface {
  label?: string;
  value: any;
}

export class IFormPersonalDataValues {
  email?: string;
  phonenumber?: number;
  initial_identification?: string;
  ispep?: PersonalInfoKeyPair;
  islocal?: PersonalInfoKeyPair;
  accepTermsAndConditions?: string;
  college?: PersonalInfoKeyPair;
  constructor(
    phonenumber: number = 0,
    email?: string,
    ispep?: PersonalInfoKeyPair,
    islocal?: PersonalInfoKeyPair,
    accepTermsAndConditions: string = "Sin elegir",
    college?: PersonalInfoKeyPair
  ) {
    this.email = email || '';
    this.phonenumber = phonenumber;
    this.ispep = ispep;
    this.islocal = islocal;
    this.accepTermsAndConditions = accepTermsAndConditions;
    this.college = college;
  }
}
