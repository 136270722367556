export interface IResponsiveManagerInterface {
  xs: boolean;
  s: boolean;
  md: boolean;
  l: boolean;
  xl: boolean;
}

export class ResponseManagerInit implements IResponsiveManagerInterface {
  xs: boolean = false;
  s: boolean = false;
  md: boolean = false;
  l: boolean = false;
  xl: boolean = false;
}
