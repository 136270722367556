export interface IsetInfoMesssages {
  title: string;
  description?: string;
  icon: string;
  arrayDescription?: Array<string>;
}
export class IAccountCreatedInfo {
  accountNumber: string | number = "";
  clientId: string | number = "";
}
export interface IFinalScreenSendEmailInterface {
  clientEmail: string;
  accountNumber: string | number;
  clientFullName: string;
  mensualLimit: string | number;
  maxLimit: string | number;
  daysLimit?: string | number;
  minimunAmountOpenAccount?: number;
  anuality?: number;
  interestRate?: string | number;
  fromSaveAmount?: string | number;
}

export interface ICreatedBiometricInfo {
    email: string,
    clientName?: string,
    activationDate?: string,
    deviceBrowser: string
    ip?: string,
    model?: string,
    os?: string
}