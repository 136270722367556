import { createReducer, on } from "@ngrx/store";
import { OcupationInfoActions, PersonalInfoActions, DocumentInfoActions, LocationInfoActions, AccountCreatedInfoActions, SelphiResponseActions, SelphiIDResponseActions } from "../../actions/cuenta-adulto/cuenta-adulto.actions";
import { IAdultAccountOrchestation } from "@interfaces/cuenta-adulto/cuenta-adulto.interface";
import { ListAccountActions } from "@app/store/actions/cuenta-adulto/list-account/list-account.actions";
import { IFormPersonalDataValues } from "@interfaces/personal-info/personal-info.interface";
import { IFormDocumentDataValues } from "@interfaces/document-fake-form/document-fake-form.interface";
import { IFormLocationInfoDataValues } from "@interfaces/location-info/location-info.interface";
import { IsetOcupationInfoToStore } from "@interfaces/ocupation-info/ocupation-info.interface";
import { IPersonTE } from "@interfaces/tribunal/tribunal.interface";
import { IAccountCreatedInfo } from "@interfaces/final-screen/final-screen.interface";
import { ICapturedInfo } from "@interfaces/identity-document-cda/identity-document-cda.interface";
import { BinnacleActions } from "@app/store/actions/binnacle/binnacle.actions";
import { IUtilsResponse } from "@interfaces/binnacle/binnacle.interface";

export const initialState: IAdultAccountOrchestation = {
  tracingData: new IUtilsResponse(),
  productAccountInfo: null,
  personalInfoForm: new IFormPersonalDataValues(),
  ocupationInfoForm: new IsetOcupationInfoToStore(),
  documentInfoForm: new IFormDocumentDataValues(),
  locationInfoForm: new IFormLocationInfoDataValues(),
  teInfoForm: new IPersonTE(),
  accountCreatedInfo: new IAccountCreatedInfo(),
  customerValidatedInfo: null,
  documentCapturedInfo: new ICapturedInfo(),
  livenessResult: null,
  matchingFacialResult: null,
};

export const adultAccountOrchestationReducer = createReducer(
  initialState,

  on(BinnacleActions.set_tracing_data, (state, payload) => {
    return {...state, tracingData: payload};
  }),
  on(ListAccountActions.get_characteristics, (state, payload) => {
    return {...state, productAccountInfo: payload};
  }),
  on(PersonalInfoActions.set_personal_info, (state, payload) => {
    return { ...state, personalInfoForm: {...state.personalInfoForm, ...payload} };
  }),
  on(OcupationInfoActions.set_ocupation_info, (state, payload) => {
    return { ...state, ocupationInfoForm: payload };
  }),
  on(DocumentInfoActions.set_document_info, (state, payload) => {
    return { ...state, documentInfoForm: payload };
  }),
  on(LocationInfoActions.set_location_info, (state, payload) => {
    return { ...state, locationInfoForm: payload };
  }),
  on(LocationInfoActions.set_te_info, (state, payload) => {
    return { ...state, teInfoForm: payload };
  }),
  on(AccountCreatedInfoActions.set_account_created_info, (state, payload) => {
    return { ...state, accountCreatedInfo: payload };
  }),
  on(LocationInfoActions.set_customer_validated, (state, payload) => {
    return { ...state, customerValidatedInfo: payload };
  }),
  on(LocationInfoActions.set_captured_document_info, (state, payload) => {
    return { ...state, documentCapturedInfo: payload };
  }),
  on(SelphiResponseActions.set_selphi_response, (state, payload) => {
    return { ...state, livenessResult: payload };
  }),
  on(SelphiIDResponseActions.set_selphi_id_response, (state, payload) => {
    return { ...state, matchingFacialResult: payload };
  }),
);
