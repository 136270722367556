import { IsetOcupationInfoToStore } from "@interfaces/ocupation-info/ocupation-info.interface";
import { IFormLocationInfoDataValues } from "@interfaces/location-info/location-info.interface";
import { IFormDocumentDataValues } from "@interfaces/document-fake-form/document-fake-form.interface";
import { IFormPersonalDataValues } from "@interfaces/personal-info/personal-info.interface";
import { createActionGroup, props } from "@ngrx/store";
import { IPersonTE } from "@interfaces/tribunal/tribunal.interface";
import { IAccountCreatedInfo } from "@interfaces/final-screen/final-screen.interface";
import { CustomerValidated, FacialAuthenticate, LivenessResult } from '@interfaces/index';
import { ICapturedInfo, IManualInfatCapturedInfo, IManualParentCapturedInfo,  } from "@interfaces/identity-document-cda/identity-document-cda.interface";

export const PersonalInfoActions = createActionGroup({
  source: "CUENTA_INFANTIL_PERSONAL_INFO_FORM",
  events: {
    set_personal_info: props<IFormPersonalDataValues>(),
  },
});

export const OcupationInfoActions = createActionGroup({
  source: "CUENTA_INFANTIL_OCUPATION_INFO_FORM",
  events: {
    set_ocupation_info: props<IsetOcupationInfoToStore>(),
  },
});

export const DocumentInfoActions = createActionGroup({
  source: "CUENTA_INFANTIL_DOCUMENT_FAKE_FORM",
  events: {
    set_document_info: props<IFormDocumentDataValues>(),
  },
});

export const LocationInfoActions = createActionGroup({
  source: "CUENTA_INFANTIL_LOCATION_INFO_FORM",
  events: {
    set_location_info: props<IFormLocationInfoDataValues>(),
    set_te_info: props<IPersonTE>(),
    set_infant_te_info: props<IPersonTE>(),
    set_customer_validated: props<CustomerValidated>(),
    set_captured_document_info: props<ICapturedInfo>(),
    set_captured_infant_document_info: props<IManualInfatCapturedInfo>(),
    set_captured_parent_plastic_document_info: props<ICapturedInfo>(),
    set_captured_parent_document_info: props<IManualParentCapturedInfo>()
  },
});

export const AccountCreatedInfoActions = createActionGroup({
  source: "CUENTA_INFANTIL_ACCOUNT_CREATED_INFO",
  events: {
    set_account_created_info: props<IAccountCreatedInfo>(),
  },
});

export const SelphiResponseActions = createActionGroup({
  source: 'SELPHI_RESPONSE',
  events: {
    'set_selphi_response': props<LivenessResult>(),
  },
});

export const SelphiIDResponseActions = createActionGroup({
  source: 'SELPHI_ID_RESPONSE',
  events: {
    'set_selphi_id_response': props<FacialAuthenticate>(),
  },
});