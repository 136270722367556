import { createActionGroup, props } from '@ngrx/store';
import { IListAccountInterface } from '@interfaces/list-account/list-account.interface';
 
export const ListAccountActions = createActionGroup({
  source: 'LIST_ACCOUNT',
  events: {
    'get_account_info': props<IListAccountInterface>(),
    'get_all_characteristics': props<IListAccountInterface>(),
    'get_characteristics': props<IListAccountInterface>(),
  },
});
 
