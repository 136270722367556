export class BirthInfo {
  day: number = 0;
  month: number = 0;
  year: number = 0;
  country: string = "";
  province: string = "";
  district: string = "";
  corregimiento: string = "";
  place: string = "";
  fullPlace: string = "";
}

export class Address {
  province: string = "";
  district: string = "";
  corregimiento: string = "";
  neighborhood: string = "";
  street: string = "";
  buildingOrHouse: string = "";
}

export class IBitacoraInfo {
  provincia: string = "";
  tomo: string = "";
  asiento: string = "";
  cedula: string = "";
  fecha_vencimiento_cedula: string = "";
  primer_nombre: string = "";
  apellido_paterno: string = "";                                
  apellido_materno: string = "";                               
  fecha_nacimiento: string = "";
  sexo: string = "";
  estado_civil: string = "";
  pais: string = "";
  lugarnacimientope: string = "";
  prov: string = "";
  distrito: string = "";
  corregimiento: string = "";
  LugarDeNacimiento: string = "";
  CodMensaje: string = "";
  barrio_residencia: string = "";
  calle_residencia: string = "";
  edificio_casa: string = "";
  nombreCedula: string = "";
  nacionalidad: string = "";
  primer_nombre_madre: string = "";
  segundo_nombre_madre: string = "";
  apellido_paterno_madre: string = "";
  apellido_materno_madre: string = "";
  primer_nombre_padre: string = "";
  apellido_paterno_padre: string = "";
  apellido_materno_padre: string = "";
  provincia_nombre: string = "";
  distrito_nombre: string = "";
  corregimiento_nombre: string = "";
  nombre_centro: string = "";
  cedula_madre: string = "";
  cedula_padre: string = "";
  NOMBRES_IMPRIMIR: string = "";
  APELLIDO_IMPRESION: string = "";
  FECHA_NACIMIENTO: string = "";
  LUGAR_NACIMIENTO: string = "";
  FECHA_SOLICITUD: string = "";
  FECHA_EMISION: string = "";
  FECHA_EXPERACION: string = "";
}

export class IPersonTE {
  firstName?: string = "";
  marriedSurname?: string = "";
  secondName?: string = "";
  firstSurname?: string = "";
  secondSurname?: string = "";
  identityDocument?: string = "";
  nationality?: string = "";
  civilStatus?: string = "";
  gender?: string = "";
  birthInfo?: BirthInfo = new BirthInfo();
  address?: Address = new Address();
  firma?: string = "";
  faceImage?: string = "";
  bitacora?: IBitacoraInfo = new IBitacoraInfo();
  identityToken?: string = "";
}
