import { KeyPairInterface } from "@components/shared/forms/element-base";
import { IFormValidated } from "@interfaces/shared/dynamic-form.interface";

export interface LocationInfoKeyPair extends KeyPairInterface {
  label?: string;
  value: any;
}

export class IFormLocationInfo extends IFormValidated {
  override formValues: IFormLocationInfoDataValues = new IFormLocationInfoDataValues();
}

export interface ILocationInterface {
  locationValues: LocationInfoKeyPair | [];
}

export class IFormLocationInfoDataValues {
  nombre?: string;
  estadoCivil?: LocationInfoKeyPair;
  civilStatus?: LocationInfoKeyPair;
  genero?: LocationInfoKeyPair;
  gender?: LocationInfoKeyPair;
  provincia?: LocationInfoKeyPair;
  province?: LocationInfoKeyPair;
  distrito?: LocationInfoKeyPair;
  district?: LocationInfoKeyPair;
  corregimiento?: LocationInfoKeyPair;
  correg?: LocationInfoKeyPair;
  barrio?: string;
  calle?: string;
  casaOEdificio?: string;

  constructor(
    nombre?: string,
    estadoCivil?: LocationInfoKeyPair,
    civilStatus?: KeyPairInterface,
    genero?: LocationInfoKeyPair,
    gender?: KeyPairInterface,
    provincia?: KeyPairInterface,
    province?: KeyPairInterface,
    distrito?: LocationInfoKeyPair,
    district?: KeyPairInterface,
    corregimiento?: LocationInfoKeyPair,
    correg?: KeyPairInterface,
    barrio?: string,
    calle?:string,
    casaOEdificio?:string
  ) {
    this.nombre = nombre;
    this.estadoCivil = estadoCivil;
    this.civilStatus = civilStatus;
    this.genero = genero;
    this.gender = gender;
    this.provincia = provincia;
    this.province = province;
    this.distrito = distrito;
    this.district = district;
    this.corregimiento = corregimiento;
    this.correg = correg;
    this.barrio = barrio;
    this.calle = calle;
    this.casaOEdificio = casaOEdificio;
  }
}


export class LocationInfoServiceValues {
  nombre?: string;
  estadoCivil?: LocationInfoKeyPair[];
  civilStatus?: LocationInfoKeyPair;
  genero?: LocationInfoKeyPair[];
  gender?: LocationInfoKeyPair;
  provincia?: LocationInfoKeyPair[];
  province?: LocationInfoKeyPair[];
  distrito?: LocationInfoKeyPair[];
  district?: LocationInfoKeyPair[];
  corregimiento?: LocationInfoKeyPair[];
  correg?: LocationInfoKeyPair[];
  barrio?: string | null;
  calle?: string | null;
  casaOEdificio?: string| null;

  constructor(
    nombre?: string,
    estadoCivil?: LocationInfoKeyPair[],
    civilStatus?: KeyPairInterface,
    genero?: LocationInfoKeyPair[],
    gender?: KeyPairInterface,
    provincia?: KeyPairInterface[],
    province?: KeyPairInterface[],
    distrito?: LocationInfoKeyPair[],
    district?: KeyPairInterface[],
    corregimiento?: LocationInfoKeyPair[],
    correg?: KeyPairInterface[],
    barrio?: string
  ) {
    this.nombre = nombre;
    this.estadoCivil = estadoCivil;
    this.civilStatus = civilStatus;
    this.genero = genero;
    this.gender = gender;
    this.provincia = provincia;
    this.province = province;
    this.distrito = distrito;
    this.district = district;
    this.corregimiento = corregimiento;
    this.correg = correg;
    this.barrio = barrio;
  }
}
