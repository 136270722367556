import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable()
export class BlockUser implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (environment.stage === 'prod') {
    //   return next.handle(req);
    // }
    const userBlockToken = localStorage.getItem('userBlockToken');
  
   if (req.url.includes('matchingFacialEncrypted') || req.url.includes('evaluateLivenessToken') ||  req.url.includes('matchingFacial')) {
      if (userBlockToken) {
        // Agrega el token al cuerpo de la solicitud
        req = req.clone({
          body: {
            ...req.body,
            userBlockToken
          }
        });
    
        return next.handle(req);

      }

  
    }
    return next.handle(req);
}
  
}
